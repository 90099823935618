import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="We’re Excited to be a part of Z103.5’s Dream Wedding Contest!" 
    description="The Smile Stylist has something innovative in their mind. A new approach to encourage couples for a smile shot by offering them perfect dream wedding package." 
    keywords="best smiles, dentist blog, teeth canada, dental clinics, oakville, toronto, mississauga, canada" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="news-press hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="blog-news-art">
              <h1>We’re Excited to be a part of Z103.5’s Dream Wedding Contest!</h1>
              <h2>One Lucky Bride and Groom Will Win a Dream Smile by Dr. Kaloti…</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <h2>Our Smile Stylist is teaming up with Z103.5 to bring one lucky couple the smiles of their dreams.</h2>

            <p className="lead">Z103.5’s Dream Wedding contest gives engaged Ontario couples a shot at winning their perfect wedding – a $50,000 value – which will include a max. $10,000 value smile makeover by the Smile Stylist himself!</p>
            <h3>Is This Real Life?!</h3>
            <p>For couples dreaming of the perfect wedding and the perfect smile to match, the Z103.5 Dream Wedding Package is the ultimate prize. In union with a stunning new smile designed by Dr. Kaloti, the wedding package will also provide the winning couple with a wedding venue, dress and suit, wedding cake, rings… and even a dreamy Honeymoon vacation! Is there anything this dream package DOESN’T include? Well, maybe batteries, but we’re sure you won’t need those for anything.</p>
            <h3>Here’s what you’ve gotta do…</h3>
            <p>To enter the Win-A-Wedding contest, simply visit Z103.5’s contest page and enter a description as to why YOU deserve to win a $50,000 Dream Wedding. There will be 10 contestants selected from online entries, and another 5 contestants chosen from Canada’s Bridal Show crowd at the Metro Toronto Convention Centre on Sunday, January 8th at 4:30pm.</p>
            <p>All chosen contestants must be in attendance at Canada’s Bridal Show on that day. Contestants must also be Ontario residents, must be engaged at the time of contest enrollment, and must be at least 19 years of age to qualify.</p>
            <h3>Our Smile Stylist wants to make you smile!</h3>
            <p>If luck isn’t your strongsuit, or you don’t see a wedding in your near future… you can still bet on our Smile Stylist to give you the smile you’ve always dreamed of! Our Dr. Kaloti uses DSD (Digital Smile Design) technology to treatment plan, and to show patients what their new smile can look like before their treatment begins. Are you interested in seeing what our Toronto cosmetic dentist can do for you? Contact us today!</p>
            <p>And if you’re betting on a Dream Wedding Package to win you that perfect smile and perfect day, we wish you the best of luck!</p>
          </div>
          <div className="col-sm-4 blog-right wow animated zoomIn" data-wow-delay="0.6s"><img alt="Win a Wedding" src={ require("../../images/desktop/contest/wedding.jpg" )} className="img-responsive" />
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage